export const ListAction = {
  createdAt: {
    type: 'dateTime',
    props: {
      required: true,
    },
  },
  deletedAt: {
    type: 'dateTime',
  },
  id: {
    props: {
      required: true,
    }, // no type property to hide from the form
  },
  listItems: {
    type: 'listItems',
    fieldArray: {
      fieldGroup: [
        {
          className: 'w-50 d-inline-block',
          key: 'item',
          type: 'input',
        },
        {
          className: 'w-25 d-inline-block',
          key: 'done',
          type: 'toggle',
        },
      ],
    },
  },
  title: {
    type: 'input',
    props: {
      required: true,
    },
  },
  uid: {
    props: {
      required: true,
    },
  },
  localUpdatedAt: {
    type: 'dateTime',
    props: {
      required: true,
    },
  },
};
