import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { doc, writeBatch } from 'firebase/firestore';
import { GenerateNotes, JsNote } from 'src/app/shared/interfaces/Note';
import {
  getFakeDatesArray,
  getFakeSentence,
  getNewId,
} from 'src/app/shared/utils';
import { getRandomInt } from '../../utils';
import { FirebaseService } from '../firebase.service';

@Injectable({
  providedIn: 'root',
})
export class NoteGeneratorService {
  batch: any;
  constructor(private fb: FirebaseService, private afs: AngularFirestore) {}

  async generateNotes(params: GenerateNotes) {
    // Get a new write batch
    this.batch = writeBatch(this.afs.firestore);
    if (!this.fb.getUser()?.user?.uid) return;
    const dateArray = getFakeDatesArray(params.from, params.to, params.qty);
    var dataArray: JsNote[] = [];
    dateArray.forEach((item) => {
      const note: JsNote = {
        docCollection: 'notes',
        docVer: 12,
        createdAt: new Date(item),
        deletedAt: null,
        description: getFakeSentence(2, 20),
        emotion: getRandomInt(0, 5),
        id: getNewId(),
        images: [],
        tags: [],
        title: getFakeSentence(2, 9),
        uid: this.fb.getUser().user.uid,
        localUpdatedAt: new Date(),
        cloudUpdatedAt: null,
      };
      dataArray.push(note);
    });
    this.fb.sendDataToServer(dataArray);
    await this.batch.commit();
    return dateArray.length;
  }

  deleteAll() {
    const sub = this.fb.notes$.subscribe(async (notes) => {
      const batch = writeBatch(this.afs.firestore);
      notes.forEach((i) => {
        batch.delete(doc(this.afs.firestore, 'notes', i.id));
      });
      await batch.commit().catch((e) => console.log(e));
      sub.unsubscribe();
    });
  }
}
