import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { doc, writeBatch } from 'firebase/firestore';
import { GenerateLists, JsList } from 'src/app/shared/interfaces/List';
import {
  getFakeSentence,
  getNewId,
  getRandomInt
} from 'src/app/shared/utils';
import { getRandomBooleanArray } from '../../utils';
import { FirebaseService } from '../firebase.service';

@Injectable({
  providedIn: 'root',
})
export class ListGeneratorService {
  batch: any;
  constructor(private fb: FirebaseService, private afs: AngularFirestore) {}

  async generateLists(params: GenerateLists) {
    // Get a new write batch
    if (!this.fb.getUser()?.user?.uid) return;
    const listsArray = getRandomBooleanArray(Number(params.listQty));
    
    var dataArray: JsList[] = [];
    listsArray.forEach((item) => {
      const itemQty = getRandomInt(
        Number(params.listItemsMin),
        Number(params.listItemsMax)
      );
      const listItems = getRandomBooleanArray(itemQty).map((i) => {
        return {
          item: getFakeSentence(1, 4),
          done: i,
        };
      });
      
      const list: JsList = {
        docCollection: 'lists',
        docVer: 12,
        createdAt: new Date(),
        deletedAt: null,
        id: getNewId(),
        listItems,
        title: getFakeSentence(1, 3),
        uid: this.fb.getUser().user.uid,
        localUpdatedAt: new Date(),
        cloudUpdatedAt: null,
        position: getRandomInt(0, 100),
      };
      dataArray.push(list);
    });
    this.fb.sendDataToServer(dataArray);
    return listsArray.length;
  }

  deleteAll() {
    const sub = this.fb.lists$.subscribe(async (lists) => {
      const batch = writeBatch(this.afs.firestore);
      lists.forEach((i) => {
        batch.delete(doc(this.afs.firestore, 'lists', i.id));
      });
      await batch.commit().catch((e) => console.log(e));
      sub.unsubscribe();
    });
  }
}
