import { AnyEntityName } from '../../interfaces/Entities';
import { TodoAction } from './TodoAction';
import { TodoGenerate } from './TodoGenerate';
import { NoteAction } from './NoteAction';
import { NoteGenerate } from './NoteGenerate';
import { ListGenerate } from './ListGenerate';
import { ListAction } from './ListAction';
import { JournalSetup } from './JournalSetup';
import { JournalAction } from './JournalAction';
import { JournalGenerate } from './JournalGenerate';
import { HabitSetup } from './HabitSetup';
import { HabitAction } from './HabitAction';
import { HabitGenerate } from './HabitGenerate';

const Types: any = {
  TodoAction,
  TodoGenerate,
  NoteAction,
  NoteGenerate,
  ListGenerate,
  ListAction,
  JournalSetup,
  JournalAction,
  JournalGenerate,
  HabitSetup,
  HabitAction,
  HabitGenerate,
};

// export function getType(entity: Entity, key: any) {
//   return Types[entity][key].type;
// }

// export function getRequired(entity: Entity, key: any) {
//   return Types[entity][key]?.required && true;
// }

// export function getHidden(entity: Entity, key: any) {
//   return Types[entity][key]?.hidden && true;
// }

export function getFormlyConfig(entity: AnyEntityName, key: any) {
  return Types[entity][key];
}
