import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { DocumentData } from '@angular/fire/compat/firestore';
import { Firestore, collection, collectionData, doc, getDocs, query, setDoc, where } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { FbHabitAction, JsHabitAction } from '../interfaces/HabitAction';
import { FbHabitSetup, JsHabitSetup } from '../interfaces/HabitSetup';
import { FbJournalAction, JsJournalAction } from '../interfaces/JournalAction';
import { FbJournalSetup, JsJournalSetup } from '../interfaces/JournalSetup';
import { FbList, JsList } from '../interfaces/List';
import { FbNote, JsNote } from '../interfaces/Note';
import { FbTodo, JsTodo } from '../interfaces/Todo';
import { convertTimeStampsFb_Js, convertTimeStampsJs_Fb } from '../utils';
import { JsEntity } from '../interfaces/Entities';
import { DataSnapshotInfo } from '../interfaces/DataSnapshotInfo';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  public todos$!: Observable<JsTodo[]>;
  public notes$!: Observable<JsNote[]>;
  public lists$!: Observable<JsList[]>;
  public journalSetups$!: Observable<JsJournalSetup[]>;
  public journalActions$!: Observable<JsJournalAction[]>;
  public habitSetups$!: Observable<JsHabitSetup[]>;
  public habitActions$!: Observable<JsHabitAction[]>;
  constructor(
    private afs: Firestore,
    private afns: Functions,
    public auth: AngularFireAuth,
    private router: Router
  ) {
  }

  async getTodos() {
    const uid = this.getUser()?.user?.uid;
    console.log('getTodos');
    if (this.todos$ || !uid) return;
    const collectionRef = collection(this.afs, 'todos');
    const q = query(collectionRef,where('uid', '==', uid), where('deletedAt', '==', null));
    this.todos$ = collectionData(q)
    .pipe(map((todos: DocumentData[]) => {
      return todos.map((todo) => convertTimeStampsFb_Js(todo as FbTodo) as JsTodo);
    }));
  }

  async getNotes() {
    console.log('getNotes');
    const uid = this.getUser()?.user?.uid;
    if (this.notes$ || !uid) return;
    const collectionRef = collection(this.afs, 'notes');
    const q = query(collectionRef,where('uid', '==', uid), where('deletedAt', '==', null));
    this.notes$ = collectionData(q)
        .pipe(map((notes: DocumentData[]) => notes.map((note) => convertTimeStampsFb_Js(note as FbNote) as JsNote)));
  }

  async getLists() {
    const uid = this.getUser()?.user?.uid;
    if (this.lists$ || !uid) return;
    const collectionRef = collection(this.afs, 'lists');
    const q = query(collectionRef,where('uid', '==', uid), where('deletedAt', '==', null));
    this.lists$ = collectionData(q)
    .pipe(map((lists: DocumentData[]) => {
      return lists.map((list) => convertTimeStampsFb_Js(list as FbList) as JsList);
    }));
  }

  async getJournalSetups() {
    const uid = this.getUser()?.user?.uid;
    if (this.journalSetups$ || !uid) return;
    const collectionRef = collection(this.afs, 'journalSetups');
    const q = query(collectionRef,where('uid', '==', uid), where('deletedAt', '==', null));
    this.journalSetups$ = collectionData(q)
    .pipe(map((journalSetups: DocumentData[]) => {
      return journalSetups.map((journalSetup) => convertTimeStampsFb_Js(journalSetup as FbJournalSetup) as JsJournalSetup);
    }));
  }

  async getJournalActions() {
    const uid = this.getUser()?.user?.uid;
    if (this.journalActions$ || !uid) return;
    const collectionRef = collection(this.afs, 'journalActions');
    const q = query(collectionRef,where('uid', '==', uid), where('deletedAt', '==', null));
    this.journalActions$ = collectionData(q)
    .pipe(map((journalActions: DocumentData[]) => {
      return journalActions.map((journalAction) => convertTimeStampsFb_Js(journalAction as FbJournalAction) as JsJournalAction);
    }));
  }

  async getHabitSetups() {
    const uid = this.getUser()?.user?.uid;
    if (this.habitSetups$ || !uid) return;
    const collectionRef = collection(this.afs, 'habitSetups');
    const q = query(collectionRef,where('uid', '==', uid), where('deletedAt', '==', null));
    this.habitSetups$ = collectionData(q)
    .pipe(map((habitSetups: DocumentData[]) => {
      return habitSetups.map((habitSetup) => convertTimeStampsFb_Js(habitSetup as FbHabitSetup) as JsHabitSetup);
    }));
  }

  async getHabitActions() {
    const uid = this.getUser()?.user?.uid;
    if (this.habitActions$ || !uid) return;
    const collectionRef = collection(this.afs, 'habitActions');
    const q = query(collectionRef,where('uid', '==', uid), where('deletedAt', '==', null));
    this.habitActions$ = collectionData(q)
    .pipe(map((habitActions: DocumentData[]) => {
      return habitActions.map((habitAction) => convertTimeStampsFb_Js(habitAction as FbHabitAction) as JsHabitAction);
    }));
  }

  createTodo(todo: JsTodo) {
    const uid = this.getUser()?.user?.uid;
    if (!todo || !uid || !todo.uid || uid !== todo.uid) return;
    const docRef = doc(this.afs, 'todos', todo.id);
    setDoc(docRef, convertTimeStampsJs_Fb(todo));
    return;
  }

  createNote(note: JsNote) {
    const uid = this.getUser()?.user?.uid;
    if (!note || !uid || !note.uid || uid !== note.uid) return;
    const docRef = doc(this.afs, 'notes', note.id);
    setDoc(docRef, convertTimeStampsJs_Fb(note));
    return;
  }

  createList(list: JsList) {
    const uid = this.getUser()?.user?.uid;
    if (!list || !uid || !list.uid || uid !== list.uid) return;
    const docRef = doc(this.afs, 'lists', list.id);
    setDoc(docRef, convertTimeStampsJs_Fb(list));
    return;
  }

  createJournalSetup(journalSetup: JsJournalSetup) {
    const uid = this.getUser()?.user?.uid;
    if (!journalSetup || !uid || !journalSetup.uid || uid !== journalSetup.uid)
      return;
    const docRef = doc(this.afs, 'journalSetups', journalSetup.id);
    setDoc(docRef, convertTimeStampsJs_Fb(journalSetup));
    return;
  }

  createJournalAction(journalAction: JsJournalAction) {
    const uid = this.getUser()?.user?.uid;
    if (
      !journalAction ||
      !uid ||
      !journalAction.uid ||
      uid !== journalAction.uid
    )
      return;
    const docRef = doc(this.afs, 'journalActions', journalAction.id);
    setDoc(docRef, convertTimeStampsJs_Fb(journalAction))
    return;
  }

  createHabitSetup(habitSetup: JsHabitSetup) {
    const uid = this.getUser()?.user?.uid;
    if (!habitSetup || !uid || !habitSetup.uid || uid !== habitSetup.uid)
      return;
    const docRef = doc(this.afs, 'habitSetups', habitSetup.id);
    setDoc(docRef, convertTimeStampsJs_Fb(habitSetup))
    return;
  }

  createHabitAction(habitAction: JsHabitAction) {
    const uid = this.getUser()?.user?.uid;
    if (!habitAction || !uid || !habitAction.uid || uid !== habitAction.uid)
      return;
    const docRef = doc(this.afs, 'habitActions', habitAction.id);
    setDoc(docRef, convertTimeStampsJs_Fb(habitAction))
    return;
  }

  updateTodo(todo: JsTodo) {
    const uid = this.getUser()?.user?.uid;
    if (!todo || !uid || !todo.uid || uid !== todo.uid) return;
    // const docRef = doc(this.afs, 'todos', todo.id);
    // updateDoc(docRef, convertTimeStampsJs_Fb(habitAction));
    // this.afs
    //   .collection('todos')
    //   .doc(todo.id)
    //   .update(convertTimeStampsJs_Fb(todo)!);
    return;
  }

  updateNote(note: JsNote) {
    const uid = this.getUser()?.user?.uid;
    if (!note || !uid || !note.uid || uid !== note.uid) return;
    // this.afs
    //   .collection('notes')
    //   .doc(note.id)
    //   .update(convertTimeStampsJs_Fb(note)!);
    return;
  }

  updateList(list: JsList) {
    const uid = this.getUser()?.user?.uid;
    if (!list || !uid || !list.uid || uid !== list.uid) return;
    // this.afs
    //   .collection('lists')
    //   .doc(list.id)
    //   .update(convertTimeStampsJs_Fb(list)!);
    return;
  }

  updateJournalSetup(journalSetup: JsJournalSetup) {
    const uid = this.getUser()?.user?.uid;
    if (!journalSetup || !uid || !journalSetup.uid || uid !== journalSetup.uid)
      return;
    // this.afs
    //   .collection('journalSetups')
    //   .doc(journalSetup.id)
    //   .update(convertTimeStampsJs_Fb(journalSetup)!);
    return;
  }

  updateJournalAction(journalAction: JsJournalAction) {
    const uid = this.getUser()?.user?.uid;
    if (
      !journalAction ||
      !uid ||
      !journalAction.uid ||
      uid !== journalAction.uid
    )
      return;
    // this.afs
    //   .collection('journalActions')
    //   .doc(journalAction.id)
    //   .update(convertTimeStampsJs_Fb(journalAction)!);
    return;
  }

  updateHabitSetup(habitSetup: JsHabitSetup) {
    const uid = this.getUser()?.user?.uid;
    if (!habitSetup || !uid || !habitSetup.uid || uid !== habitSetup.uid)
      return;
    // this.afs
    //   .collection('habitSetups')
    //   .doc(habitSetup.id)
    //   .update(convertTimeStampsJs_Fb(habitSetup)!);
    return;
  }

  updateHabitAction(habitAction: JsHabitAction) {
    const uid = this.getUser()?.user?.uid;
    if (!habitAction || !uid || !habitAction.uid || uid !== habitAction.uid)
      return;
    // this.afs
    //   .collection('habitActions')
    //   .doc(habitAction.id)
    //   .update(convertTimeStampsJs_Fb(habitAction)!);
    return;
  }

  setUser(user: any) {
    localStorage.setItem('user', JSON.stringify(user));
    switch (this.router.url) {
      case '/todos':
        this.getTodos();
        break;
      case '/habits':
        this.getHabitSetups();
        this.getHabitActions();
        break;
      case '/journals':
        this.getJournalSetups();
        this.getJournalActions();
        break;
      case '/notes':
        this.getNotes();
        break;
      case '/lists':
        this.getLists();
        break;
      default:
        break;
    }
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user')!);
  }

  signoutUser() {
    localStorage.setItem('user', 'null');
    this.todos$ = of([]);
  }

  async sendDataToServer(payload: JsEntity[]) {
    console.log('sendDataToServer');
    type Payload = {
      data: string;
    }
    const functionPayload: Payload[] =  [];
    payload.forEach((i) => {
      functionPayload.push({data: JSON.stringify(i)});
    });
    const callable = httpsCallable(this.afns, 'sync-allModules');
    const data = await callable({ 'dataList': functionPayload });
    console.log(data);
  }

  async createDataSnapshot(title: string) {
    console.log('create datasnapshot');
    const uid = this.getUser()?.user?.uid;
    if(!uid) return;
    type Payload = {
      userId: string,
      title: string
    }
    const functionPayload: Payload =  {userId: uid, title: title};
    
    const callable = httpsCallable(this.afns, 'admin-createSnapshot');
    const data = await callable(functionPayload);
    console.log(data);
  }

  async restoreDataSnapshot(snapshotId: number) {
    console.log('restore datasnapshot');
    const uid = this.getUser()?.user?.uid;
    if(!uid) return;
    type Payload = {
      userId: string,
      snapshotId: number
    }
    const functionPayload: Payload =  {userId: uid, snapshotId: snapshotId};
    
    const callable = httpsCallable(this.afns, 'admin-restoreSnapshot');
    const data = await callable(functionPayload);
    console.log(data);
  }

  async deleteDataSnapshot(snapshotId: number) {
    console.log('delete datasnapshot');
    type Payload = {
      snapshotId: number
    }
    const functionPayload: Payload =  {snapshotId: snapshotId};
    
    const callable = httpsCallable(this.afns, 'admin-deleteSnapshot');
    const data = await callable(functionPayload);
    console.log(data);
  }

  async getDataSnapshotsInfo() : Promise<DataSnapshotInfo[]> {
    const uid = this.getUser()?.user?.uid;
    if (!uid) return [];
    const collectionRef = collection(this.afs, 'dataSnapshots');
    const snapshots = await getDocs(collectionRef);
    console.log(snapshots.docs.length);
    return snapshots.docs.map((doc) => doc.data() as DataSnapshotInfo);
  }
}
