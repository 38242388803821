import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { connectFirestoreEmulator, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { DateFnsModule } from 'ngx-date-fns';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DataBackupComponent } from './features/data-backup/data-backup.component';
import { HabitsComponent } from './features/habits/habits.component';
import { JournalsComponent } from './features/journals/journals.component';
import { ListsComponent } from './features/lists/lists.component';
import { NotesComponent } from './features/notes/notes.component';
import { TodosComponent } from './features/todos/todos.component';
import { UserComponent } from './features/user/user.component';
import { MeDateFilterPipe } from './filter-past-only.pipe';
import { NullToArrayPipe } from './null-to-array.pipe';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { FormlyListItemRepeatComponent } from './shared/components/formly/formly-list-item-repeat.component';
import { MeDateTimePickerComponent } from './shared/components/me-date-time-picker/me-date-time-picker.component';

// If using Moment
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'llll',
  },
  display: {
    dateInput: 'llll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    TodosComponent,
    HabitsComponent,
    JournalsComponent,
    NotesComponent,
    ListsComponent,
    UserComponent,
    DialogComponent,
    MeDateFilterPipe,
    MeDateTimePickerComponent,
    NullToArrayPipe,
    FormlyListItemRepeatComponent,
    DataBackupComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    NgJsonEditorModule,
    DateFnsModule.forRoot(),
    NgPipesModule,
    ReactiveFormsModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    FormsModule,
    FilterPipeModule,
    FormlyModule.forRoot({
      types: [
        { name: 'dateTime', component: MeDateTimePickerComponent },
        { name: 'listItems', component: FormlyListItemRepeatComponent },
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
      ],
    }),
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    MatDatepickerModule,
    MatSnackBarModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.useEmulators) {
        console.log('Using the emulator! firestore')
        connectFirestoreEmulator(firestore, 'localhost', 8080)
      }
      console.log('Using the emulator! adfafirestore')
      return firestore
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      if (environment.useEmulators) {
        console.log('Using the emulator! function')
        connectFunctionsEmulator(functions, 'localhost', 5001)
      }
      return functions
    }),
    provideStorage(() => getStorage()),
    AngularFirestoreModule.enablePersistence(),
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
function resolvePersistenceEnabled(arg0: boolean): any {
  throw new Error('Function not implemented.');
}

