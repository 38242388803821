import { Timestamp } from '@angular/fire/firestore';

export const currentDocVersion: number = 12;

export interface BaseModel {
    id: string;
    uid: string;
    docVer: number;
    docCollection: string;
    localUpdatedAt: Timestamp | Date;
    cloudUpdatedAt: Timestamp | Date | null;
  }