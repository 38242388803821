import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DataSnapshotInfo } from 'src/app/shared/interfaces/DataSnapshotInfo';
import { FirebaseService } from 'src/app/shared/services/firebase.service';

@Component({
  selector: 'app-data-backup',
  templateUrl: './data-backup.component.html',
  styleUrls: ['./data-backup.component.scss']
})
export class DataBackupComponent implements OnInit {
  
  dataSnapshots: DataSnapshotInfo[] = [];
  constructor(public fbService: FirebaseService, public dialog: MatDialog) { 
        this.fetchDataSnapshtsInfo();
      }

  ngOnInit(): void {
  }

  async fetchDataSnapshtsInfo() {
    this.dataSnapshots = await this.fbService.getDataSnapshotsInfo();
  }

  showCreateBackupDialog() {
    const dialogRef = this.dialog.open(DialogCreateDataSnapshot,{ data: {title: ''}},);

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed');
      if (result) {
        console.log(result)
                await this.fbService.createDataSnapshot(result);
        this.fetchDataSnapshtsInfo();
              }
    });
  }

  showRestoreDataConfirmationDialog(snapshotId: number) {
    const dialogRef = this.dialog.open(DialogRestoreDataConfirmation, {
      data: {snapshotId: snapshotId},
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed');
      if (result) {
        await this.fbService.restoreDataSnapshot(snapshotId);
      }
    });
  }

  showDeleteConfirmationDialog(snapshotId: number) {
    const dialogRef = this.dialog.open(DialogDeleteDataSnapshot, {
      data: {snapshotId: snapshotId},
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed');
      if (result) {
        this.dataSnapshots = [];
        await this.fbService.deleteDataSnapshot(snapshotId);
        this.fetchDataSnapshtsInfo();
      }
    });
  }
}

export interface DialogData {
  snapshotId: string;
  deletePreviousData:boolean;
}

@Component({
  selector: 'dialog-restore-data-confirmation',
  template: `
  <h1 mat-dialog-title>Restore Data</h1>
  <div mat-dialog-content>
    <p>Do you want to restore this snapshot (Id: {{ data.snapshotId }}) into your account?</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
  </div>
`,
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, MatCheckboxModule],
})
export class DialogRestoreDataConfirmation {
  constructor(
    public dialogRef: MatDialogRef<DialogRestoreDataConfirmation>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogCreateBackupData {
  title: string;
}

@Component({
  selector: 'dialog-create-data-snapshot',
  template: `
  <h1 mat-dialog-title>Create New Backup</h1>
  <div mat-dialog-content>
    <p>Name your backup</p>
    <mat-form-field>
      <!-- <mat-label>Favorite Animal</mat-label> -->
      <input matInput [(ngModel)]="data.title">
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.title" cdkFocusInitial>Create</button>
</div>
`,
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule],
})
export class DialogCreateDataSnapshot {
  deleteOldAccountData: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<DialogCreateDataSnapshot>,
    @Inject(MAT_DIALOG_DATA) public data: DialogCreateBackupData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'dialog-delete-data-snapshot',
  template: `
  <h1 mat-dialog-title>Delete snapshot</h1>
  <div mat-dialog-content>
    <p>Are you sure you want to delete snapshot(Id: {{ data.snapshotId }})?</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
  </div>
`,
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule],
})
export class DialogDeleteDataSnapshot {
  deleteOldAccountData: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<DialogDeleteDataSnapshot>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

