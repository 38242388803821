import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FirebaseService } from '../../shared/services/firebase.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import { JsTodo } from '../../shared/interfaces/Todo';
import { AnyEntityName } from '../../shared/interfaces/Entities';
import { TodoGeneratorService } from '../../shared/services/generators/todo-generator.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-todos',
  templateUrl: './todos.component.html',
  styleUrls: ['./todos.component.scss'],
})
export class TodosComponent implements OnInit {
  public todos: JsTodo[] = [];
  public unscheduledArray: JsTodo[] = [];
  public showUnscheduled: boolean = false;
  public searchInput = {
    title: '',
  };
  constructor(
    public fbService: FirebaseService,
    public dialog: MatDialog,
    private tgs: TodoGeneratorService,
    private _snackBar: MatSnackBar
  ) {
    this.fbService.getTodos();
  }

  ngOnInit(): void {}

  toggle(e: any) {
    this.showUnscheduled = e.checked;
  }

  // type = 'TodoAction', 'TodoGenerate' etc...
  // payload = actual dat. eg: todo
  // action = only in result. 'Update', 'Create', 'Generate'
  openUpdateDialog(todo: JsTodo) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        type: 'TodoAction' as AnyEntityName,
        payload: todo,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!res?.action) return;
      if (res.action === 'Update') {
        this.fbService.updateTodo(res.payload);
      } else if (res.action === 'Create') {
        this.fbService.createTodo(res.payload);
      }
    });
  }

  openDeleteAllDialog() {
    var result = confirm(
      'Do you want to DELETE ALL TODOS permananently from the database?'
    );
    if (result == true) {
      this.tgs.deleteAll();
    }
  }

  openGenerateDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        type: 'TodoGenerate' as AnyEntityName,
        payload: {
          from: new Date(),
          to: new Date(),
          qty: 5,
          isDone: 2,
        },
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result?.payload) return;
      const total = await this.tgs
        .generateTodos(result.payload)
        .catch((e) => console.log(e));
      this._snackBar.open(`${total} todos created!!`, 'Close', {
        duration: 3000,
      });
    });
  }
}
