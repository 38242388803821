export const HabitSetup = {
  createdAt: {
    type: 'dateTime',
    props: {
      required: true,
    },
  },
  deletedAt: {
    type: 'dateTime',
  },
  endAt: {
    type: 'dateTime',
  },
  numericGoal: {
    type: 'input',
  },
  numericUnit: {
    type: 'input',
  },
  habitOptions: {
    type: 'input',
  },
  habitType: {
    type: 'input',
  },
  id: {
    props: {
      required: true,
    }, // no type property to hide from the form
  },
  title: {
    props: {
      required: true,
    },
  },
  reminderAt: {
    type: 'dateTime',
  },
  startAt: {
    type: 'dateTime',
  },
  isStartTimeSet: {
    type: 'toggle',
  },
  uid: {
    props: {
      required: true,
    },
  },
  localUpdatedAt: {
    type: 'dateTime',
    props: {
      required: true,
    },
  },
  tags: {
    type: 'textarea',
  },
};
