<!-- <div>{{fbService.journalSetups$ | async | json}}</div> -->
<!-- <div>{{fbService.journalActions$ | async | json}}</div> -->
<div class="d-flex flex-column">
  <div class="h5 m-0 p-3 search-bar">
    <input type="text" [(ngModel)]="searchInput.title" placeholder="Title search">
    <button class="btn btn-primary float-end" (click)="openGenerateDialog()">Generate Journals</button>
    <button class="btn btn-danger float-end me-3" (click)="openDeleteAllDialog()">Delete All</button>
  </div>
  <div class="col-wrapper d-flex flex-row flex-grow-1">
    <div class="flex-grow-1">
      <div class="fs-6 lh-lg ps-2">Past ({{(fbService.journalActions$ | async | meDateFilter:'past':'startAt' |
        filterBy:
        searchInput)?.length}})</div>
      <div class="scroll-container">

        <!-- Past -->
        <div *ngFor="let item of fbService.journalActions$ | async | meDateFilter:'past':'startAt' | filterBy:
        searchInput | orderBy: '-date'" (click)="openUpdateDialog(item)">
          <div *ngIf="item.startAt && (item.startAt | dfnsIsPast)" class="card">
            <div class="fs-6 lh-base text-break">{{item.title}}</div>
            <div class="text-muted text-break">{{item.description}}</div>
            <div class="text-muted text-break">{{item.startAt | date: 'd/M/yy'}} {{item.time ?
              (item.time | date:
              'shortTime') : ''}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow-1">
      <div class="fs-6 lh-lg ps-2">Today ({{(fbService.journalActions$ | async | meDateFilter:'today':'startAt' |
        filterBy:
        searchInput)?.length}})
      </div>
      <div class="scroll-container">
        <!-- Today -->
        <div *ngFor="let item of fbService.journalActions$ | async| meDateFilter:'today':'startAt'| filterBy:
        searchInput | orderBy: '-date'" class="card" (click)="openUpdateDialog(item)">
          <div>
            <div class="fs-6 lh-base text-break">{{item.title}}</div>
            <div class="text-muted text-break">{{item.description}}</div>
            <div class="text-muted text-break">{{item.startAt | date: 'd/M/yy'}} {{item.time ?
              (item.time | date:
              'shortTime') : ''}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow-1">
      <div class="fs-6 lh-lg ps-2">Future ({{(fbService.journalSetups$ | async )?.length}})</div>
      <div class="scroll-container">

        <!-- Future -->
        <div *ngFor="let item of fbService.journalSetups$ | async" class="card" (click)="openUpdateDialog(item)">
          <div>
            <div class="fs-6 lh-base text-break">{{item.title}}</div>
            <div class="text-muted text-break">{{item.startAt | date: 'd/M/yy'}} - {{item.endAt | date: 'd/M/yy'}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
