<div class="d-flex flex-column">
  <div class="h5 m-0 p-3 search-bar">
    <input type="text" [(ngModel)]="searchInput.title" placeholder="Title search">
    <button class="btn btn-primary float-end" (click)="openGenerateDialog()">Generate Lists</button>
    <button class="btn btn-danger float-end me-3" (click)="openDeleteAllDialog()">Delete All</button>
  </div>
  <div class="col-wrapper d-flex flex-row flex-grow-1">


    <div class="flex-grow-1">
      <div class="fs-6 lh-lg ps-2">Lists ({{(fbService.lists$ | async | nullToArray | filterBy:
        searchInput)?.length}})</div>
      <div class="scroll-container">
        <!-- Past -->
        <div *ngFor="let item of fbService.lists$ | async | nullToArray | filterBy:
        searchInput" (click)="openUpdateDialog(item)" (mouseenter)="selectedList = item">
          <div class="card" [ngClass]="{selected: item.id === selectedList?.id}">
            <div class="fs-6 lh-base text-break">{{item.title}}</div>
            <div class="text-muted text-break">{{item.listItems ? (item.listItems | pluck: 'done' | without:
              [false]).length : 0}} /
              {{item.listItems ?
              item.listItems.length : 0}}</div>
          </div>
        </div>
      </div>
    </div>


    <div class="flex-grow-1">
      <div class="fs-6 lh-lg ps-2">List Items ({{selectedList?.listItems ?.length || 0}})
      </div>
      <div class="scroll-container">
        <!-- Today -->
        <div *ngFor="let item of selectedList?.listItems; let i = index">
          <div class="card selected">
            <div class="fs-6 lh-base text-break">{{item.item}}</div>
            <div class="text-muted text-break">{{selectedList && selectedList.listItems &&
              selectedList.listItems[i].done ?
              'Check' : 'Uncheck'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
