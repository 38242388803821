import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import * as _ from 'lodash';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { getNewId } from '../../utils';
import { convertTimeStampsJs_String } from '../../utils';
import {
  AnyEntityName,
  AnyEntity,
  CoreEntity,
} from '../../interfaces/Entities';
import { getFormlyConfig } from '../../services/formlyMaps/FormlyTypeMapService';
import { JsTodo } from '../../interfaces/Todo';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  editorOptions: JsonEditorOptions;
  stringifiedData!: {};
  form = new FormGroup({});
  model!: AnyEntity;
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  isGenerate = false;
  @ViewChild(JsonEditorComponent, { static: false }) editor:
    | JsonEditorComponent
    | undefined;
  editView = true;
  editButtonDisabled = true;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: AnyEntityName;
      payload: JsTodo;
    },
    private cd: ChangeDetectorRef
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.expandAll = true;
    this.editorOptions.mode = 'view';
  }

  ngOnInit(): void {
    if (this.data.type.includes('Generate')) this.isGenerate = true;
    this.stringifiedData = convertTimeStampsJs_String(this.data.payload) as {};
    this.model = { ...this.data.payload };
    for (const key of Object.keys(this.model)) {
      const fieldConfig = {
        key: key,
        ...getFormlyConfig(this.data.type, key),
      };
      if (fieldConfig.props) {
        fieldConfig.props.label = key;
      } else {
        fieldConfig.props = {
          label: key,
        };
      }
      this.fields.push(fieldConfig);
    }
    this.fields.sort(function (a, b) {
      if (a.props!.label! < b.props!.label!) {
        return -1;
      }
      if (a.props!.label! > b.props!.label!) {
        return 1;
      }
      return 0;
    });

    this.form.valueChanges.subscribe((val) => {
      this.model = val as AnyEntity;
      this.editButtonDisabled = _.isEqual(this.data.payload, val);
      this.cd.detectChanges();
    });
  }

  onDelete() {
    this.dialogRef.close({
      action: 'Update',
      payload: {
        ...this.model,
        id: (this.model as CoreEntity).id,
        deletedAt: new Date(),
        localUpdatedAt: new Date(),
      },
    });
  }

  onDuplicate() {
    this.dialogRef.close({
      action: 'Create',
      payload: {
        ...this.model,
        id: getNewId(),
        createdAt: new Date(),
        localUpdatedAt: new Date(),
      },
    });
  }

  onSave() {
    this.dialogRef.close({
      action: 'Update',
      payload: {
        ...this.model,
        id: (this.model as CoreEntity).id,
        localUpdatedAt: new Date(),
      },
    });
  }

  onReset() {
    this.model = { ...this.data.payload };
  }

  onEdit() {
    this.editView = true;
  }

  seeJson() {
    this.editView = false;
  }

  onGenerate() {
    this.dialogRef.close({
      action: 'Generate',
      payload: {
        ...this.model,
      },
    });
  }
}
