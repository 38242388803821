import { Pipe, PipeTransform } from '@angular/core';
import {
  endOfToday,
  getUnixTime,
  isAfter,
  isBefore,
  isToday,
  isValid,
  startOfToday,
} from 'date-fns';
import { JsEntity, JsEntityKey } from './shared/interfaces/Entities';

@Pipe({
  name: 'meDateFilter',
})
export class MeDateFilterPipe implements PipeTransform {
  transform(
    arr: JsEntity[] | null,
    type: 'past' | 'today' | 'future' | 'unscheduled',
    propName: JsEntityKey
  ): JsEntity[] {
    if (!arr) return [];
    return arr.filter((item: JsEntity) => {
      // @ts-ignore
      if (!item[propName] || !isValid(item[propName])) {
        return false;
      } else if (type === 'past') {
        // @ts-ignore
        return isBefore(item[propName] as Date, startOfToday());
      } else if (type === 'today') {
        // @ts-ignore
        return isToday(item[propName] as Date);
      } else if (type === 'future') {
        // @ts-ignore
        return isAfter(item[propName] as Date, endOfToday());
      } else if (type === 'unscheduled') {
        // @ts-ignore
        return getUnixTime(item[propName] as Date) === 0;
      }
      return false;
    });
  }
}
