export const HabitGenerate = {
  cType: {
    type: 'radio',
    props: {
      required: true,
      options: [
        { value: 'boolean', label: 'boolean' },
        { value: 'numeric', label: 'numeric' },
        { value: 'single', label: 'single' },
        { value: 'multiple', label: 'multiple' },
      ],
    },
  },
  dateFrom: {
    type: 'dateTime',
    props: {
      required: true,
      label: 'From',
    },
  },
  dateTo: {
    type: 'dateTime',
  },
  sun: {
    type: 'toggle',
    props: {
      required: true,
    },
  },
  mon: {
    type: 'toggle',
    props: {
      required: true,
    },
  },
  tue: {
    type: 'toggle',
    props: {
      required: true,
    },
  },
  wed: {
    type: 'toggle',
    props: {
      required: true,
    },
  },
  thu: {
    type: 'toggle',
    props: {
      required: true,
    },
  },
  fri: {
    type: 'toggle',
    props: {
      required: true,
    },
  },
  sat: {
    type: 'toggle',
    props: {
      required: true,
    },
  },
};
