import { Timestamp, serverTimestamp } from '@angular/fire/firestore';
import { faker } from '@faker-js/faker';
import { convertTimestamps } from 'convert-firebase-timestamp';
import { isValid, startOfDay } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import {
  AnyEntity,
  FbEntity,
  JsEntity,
  StringEntity,
} from './interfaces/Entities';

export function convertTimeStampsFb_Js(obj: FbEntity): JsEntity {
  return convertTimestamps(obj) as JsEntity;
}

export function convertTimeStampsJs_String(obj: AnyEntity | undefined) {
  if (!obj) return null;
  const newObj: { [key: string]: any } = {};
  for (let [key, value] of Object.entries(obj)) {
    if (value && isValid(value)) {
      newObj[key] = value.toString();
    } else {
      newObj[key] = value;
    }
  }
  return newObj as StringEntity;
}

export function convertTimeStampsString_Js(obj: StringEntity | undefined) {
  if (!obj) return null;
  const newObj: { [key: string]: any } = {};
  for (let [key, value] of Object.entries(obj)) {
    if (
      value &&
      typeof value === 'string' &&
      Date.parse(value) &&
      isValid(Date.parse(value))
    ) {
      newObj[key] = Date.parse(value);
    } else {
      newObj[key] = value;
    }
  }
  return newObj as JsEntity;
}

export function convertTimeStampsJs_Fb(
  obj: JsEntity | undefined
): FbEntity | null {
  if (!obj) return null;
  const newObj: { [key: string]: any } = {};
  for (let [key, value] of Object.entries(obj)) {
    if (!Array.isArray(key) && key.endsWith('At') && value && isValid(value)) {
      newObj[key] = Timestamp.fromMillis(value);
    } else {
      newObj[key] = value;
    }
  }
  newObj['cloudUpdatedAt'] = serverTimestamp();
  return newObj as FbEntity;
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export function getRandomBoolean() {
  return Math.random() < 0.5;
}

export function getRandomBooleanArray(num: number) {
  return [...Array(num).keys()].map((i) => getRandomBoolean());
}

export function getNewId() {
  return uuidv4();
}

export function getFakeSentence(min: number, max: number) {
  const num = faker.datatype.number({ min, max });
  return faker.random.words(num);
}

export function getFakeDatesArray(from: Date, to: Date, num: number) {
  return faker.date.betweens(from, to, num).map((i) => startOfDay(i));
}
