import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FirebaseService } from '../../shared/services/firebase.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import { JsJournalSetup } from '../../shared/interfaces/JournalSetup';
import { JsJournalAction } from '../../shared/interfaces/JournalAction';
import { AnyEntityName } from '../../shared/interfaces/Entities';
import { JournalGeneratorService } from '../../shared/services/generators/journal-generator.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-journals',
  templateUrl: './journals.component.html',
  styleUrls: ['./journals.component.scss'],
})
export class JournalsComponent implements OnInit {
  public searchInput = {
    title: '',
  };
  constructor(
    public fbService: FirebaseService,
    public dialog: MatDialog,
    private jgs: JournalGeneratorService,
    private _snackBar: MatSnackBar
  ) {
    this.fbService.getJournalSetups();
    this.fbService.getJournalActions();
  }

  ngOnInit(): void {}

  openDeleteAllDialog() {
    var result = confirm(
      'Do you want to DELETE ALL JOURNALS permananently from the database?'
    );
    if (result == true) {
      this.jgs.deleteAll();
    }
  }

  // type = 'JournalAction', 'JournalGenerate' etc...
  // payload = actual dat. eg: journal
  // action = only in result. 'Update', 'Create', 'Generate'
  openUpdateDialog(journal: JsJournalSetup) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        type: 'JournalSetup' as AnyEntityName,
        payload: journal,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!res?.action) return;
      if (res.action === 'Update') {
        this.fbService.updateJournalSetup(res.payload);
      } else if (res.action === 'Create') {
        this.fbService.createJournalSetup(res.payload);
      }
    });
  }

  openGenerateDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        type: 'JournalGenerate' as AnyEntityName,
        payload: {
          dateFrom: new Date(),
          dateTo: null,
          sun: true,
          mon: true,
          tue: true,
          wed: true,
          thu: true,
          fri: true,
          sat: true,
        },
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result?.payload) return;
      await this.jgs.generateJournals(result.payload);
      this._snackBar.open(`Journal created!!`, 'Close', {
        duration: 3000,
      });
    });
  }
}
