<div class="d-flex flex-column">
  <div class="h5 m-0 p-3 search-bar">
    <input type="text" [(ngModel)]="searchInput.title" placeholder="Title search">
    <button class="btn btn-primary float-end" (click)="openGenerateDialog()">Generate Todos</button>
    <button class="btn btn-danger float-end me-3" (click)="openDeleteAllDialog()">Delete All</button>
  </div>
  <div class="col-wrapper d-flex flex-row flex-grow-1">
    <div class="flex-grow-1">
      <div class="fs-6 lh-lg ps-2">Past ({{(fbService.todos$ | async | meDateFilter:'past':'startAt' | filterBy:
        searchInput)?.length}})</div>
      <div class="scroll-container">

        <!-- Past -->
        <div *ngFor="let item of fbService.todos$ | async | meDateFilter:'past':'startAt' | filterBy:
        searchInput | orderBy: '-date'" (click)="openUpdateDialog(item)">
          <div *ngIf="item.startAt && (item.startAt | dfnsIsPast)" class="card">
            <div class="fs-6 lh-base text-break">{{item.title}}</div>
            <div class="text-muted text-break">{{item.description}}</div>
            <div class="text-muted text-break">{{item.startAt | date: 'd/M/yy'}} {{item.time ?
              (item.time | date:
              'shortTime') : ''}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow-1">
      <div class="fs-6 lh-lg ps-2">Today ({{(fbService.todos$ | async | meDateFilter:'today':'startAt' | filterBy:
        searchInput)?.length}})
        <mat-slide-toggle color="primary" class="float-end px-2" name="showUnscheduled" (change)="toggle($event)">
        </mat-slide-toggle>
        <span class="float-end">Unscheduled ({{(fbService.todos$ | async |
          meDateFilter:'unscheduled':'startAt'| filterBy:
          searchInput)?.length}})</span>
      </div>
      <div class="scroll-container">

        <!-- Unscheduled -->
        <div *ngIf="showUnscheduled">
          <div *ngFor="let item of fbService.todos$ | async| meDateFilter:'unscheduled':'startAt' | filterBy:
        searchInput| orderBy: '-date'" class="card bg-info" (click)="openUpdateDialog(item)">
            <div>
              <div class="fs-6 lh-base text-break">{{item.title}}</div>
              <div class="text-muted text-break">{{item.description}}</div>
              <!-- <div class="text-muted text-break">{{item.startAt | date: 'short'}}</div> -->
            </div>
          </div>
        </div>

        <!-- Today -->
        <div *ngFor="let item of fbService.todos$ | async| meDateFilter:'today':'startAt'| filterBy:
        searchInput | orderBy: '-date'" class="card" (click)="openUpdateDialog(item)">
          <div>
            <div class="fs-6 lh-base text-break">{{item.title}}</div>
            <div class="text-muted text-break">{{item.description}}</div>
            <div class="text-muted text-break">{{item.startAt | date: 'd/M/yy'}} {{item.time ?
              (item.time | date:
              'shortTime') : ''}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow-1">
      <div class="fs-6 lh-lg ps-2">Future ({{(fbService.todos$ | async | meDateFilter:'future':'startAt' | filterBy:
        searchInput)?.length}})</div>
      <div class="scroll-container">

        <!-- Future -->
        <div *ngFor="let item of fbService.todos$ | async | meDateFilter:'future':'startAt' | filterBy:
        searchInput| orderBy: '-date'" class="card" (click)="openUpdateDialog(item)">
          <div>
            <div class="fs-6 lh-base text-break">{{item.title}}</div>
            <div class="text-muted text-break">{{item.description}}</div>
            <div class="text-muted text-break">{{item.startAt | date: 'd/M/yy'}} {{item.time ?
              (item.time | date:
              'shortTime') : ''}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
