<div class="wrapper d-flex flex-column">
  <div class="pb-3 btn-row">
    <span class="lh-base fs-4" *ngIf="isGenerate">{{data.type}}</span>
    <button *ngIf="!isGenerate" [disabled]="!editButtonDisabled" class="btn btn-danger me-3"
      (click)="onDelete()">Delete</button>
    <button *ngIf="!isGenerate" [disabled]="!editButtonDisabled" class="btn btn-secondary me-3"
      (click)="onDuplicate()">Duplicate</button>
    <button *ngIf="!isGenerate" [disabled]="editButtonDisabled" class="btn btn-secondary me-3"
      (click)="onReset()">Reset</button>
    <button *ngIf="!isGenerate && editView" [disabled]="!editButtonDisabled" class="btn btn-outline-info"
      (click)="seeJson()">JSON</button>
    <button *ngIf="!isGenerate && !editView" class="btn btn-primary float-end" (click)="onEdit()">Edit</button>
    <button *ngIf="!isGenerate && editView" class="btn btn-primary float-end" (click)="onSave()"
      [disabled]="editButtonDisabled || form.invalid">Save</button>
    <button *ngIf="isGenerate" class="btn btn-primary float-end" (click)="onGenerate()"
      [disabled]="form.invalid">Generate</button>
  </div>

  <div class="container pt-3">
    <div *ngIf="!editView">
      <json-editor class="col-md-12" [options]="editorOptions" [data]="stringifiedData"></json-editor>
    </div>

    <form [formGroup]="form" *ngIf="editView">
      <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    </form>
  </div>
</div>
