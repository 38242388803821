import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-list-item-repeat',
  template: `
    <div class="fw-bold">{{ props.label }}</div>
    <div *ngFor="let f of field.fieldGroup; let i = index">
      <formly-field [field]="f"></formly-field>
    </div>
  `,
})
export class FormlyListItemRepeatComponent extends FieldArrayType {}
