export const NoteAction = {
  createdAt: {
    type: 'dateTime',
    props: {
      required: true,
    },
  },
  startAt: {
    type: 'dateTime',
  },
  deletedAt: {
    type: 'dateTime',
  },
  emotion: {
    type: 'input',
  },
  id: {
    props: {
      required: true,
    }, // no type property to hide from the form
  },
  images: {
    type: 'textarea',
  },
  imagesDeletedAt: {
    type: 'textarea',
    props: {
      readonly: true,
    },
  },
  description: {
    type: 'input',
    props: {
      required: true,
    },
  },
  tags: {
    type: 'textarea',
  },
  title: {
    type: 'input',
    props: {
      required: true,
    },
  },
  uid: {
    props: {
      required: true,
    },
  },
  localUpdateAt: {
    type: 'dateTime',
    props: {
      required: true,
    },
  },
};
