<mat-toolbar>
  <button (click)="toggleSidenav()" mat-icon-button class="example-icon"
    aria-label="Example icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button>
  <span>Mevolve Admin</span>
  <span class="env badge text-bg-primary" *ngIf="!env.production">DEV</span>
  <span class="spacer"></span>
</mat-toolbar>
<mat-drawer-container class="main-container">
  <mat-drawer [opened]="showSidenav" #drawer mode="side">
    <div class="d-flex flex-column h-100">
      <button mat-button class="btn btn-primary" routerLink="/todos" routerLinkActive="btn-secondary">Todos</button>
      <button mat-button class="btn btn-primary" routerLink="/habits" routerLinkActive="btn-secondary">Habits</button>
      <button mat-button class="btn btn-primary" routerLink="/journals"
        routerLinkActive="btn-secondary">Journals</button>
      <button mat-button class="btn btn-primary" routerLink="/notes" routerLinkActive="btn-secondary">Notes</button>
      <button mat-button class="btn btn-primary" routerLink="/lists" routerLinkActive="btn-secondary">Lists</button>
      <button mat-button class="btn btn-primary" routerLink="/user" routerLinkActive="btn-secondary">User</button>
      <mat-divider class="mb-2"></mat-divider>
      <button mat-button class="btn btn-primary" routerLink="/dataBackup" routerLinkActive="btn-secondary">Data Backup</button>
      <div class="flex-grow-1"></div>
      <div *ngIf="auth.user | async as user; else showLogin">
        <h6 class="text-center text-light">{{ user.displayName }}</h6>
        <button class="btn btn-warning" (click)="logout()">Logout</button>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
<ng-template #showLogin>
  <button class="btn btn-light" (click)="login()">
    <i class="bi-google text-primary"></i>&nbsp;&nbsp;&nbsp;<span>Login</span>
  </button>
</ng-template>
