import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { doc, writeBatch } from 'firebase/firestore';
import { GenerateHabitSetups, JsHabitSetup } from 'src/app/shared/interfaces/HabitSetup';
import {
  getFakeSentence,
  getNewId
} from 'src/app/shared/utils';
import { habitType } from '../../interfaces/HabitSetup';
import { FirebaseService } from '../firebase.service';

@Injectable({
  providedIn: 'root',
})
export class HabitGeneratorService {
  batch: any;
  constructor(private fb: FirebaseService, private afs: AngularFirestore) {}

  async generateHabits(params: GenerateHabitSetups) {
   var habitSetup: JsHabitSetup = {
      docCollection: 'habitSetups',
      docVer: 12,
      createdAt: new Date(),
      deletedAt: null,
      endAt: params.dateTo,
      numericGoal: 20,
      numericUnit: 'Pushups',
      habitOptions:
        params.cType === 'single' || params.cType === 'multiple'
          ? [...getFakeSentence(3, 7).split(' ')]
          : [],
      habitType: params.cType as habitType,
      id: getNewId(),
      reminderAt: [],
      startAt: params.dateFrom,
      isStartTimeSet: false,
      title: getFakeSentence(2, 4),
      uid: this.fb.getUser().user.uid,
      localUpdatedAt: new Date(),
      cloudUpdatedAt: null,
      tags:[],
      repeat: ["daily"],
      timerGoal: null,
      alarmSoundType: 'none',
      isVibrationEnabled: true,
    };
    this.fb.sendDataToServer([habitSetup]);
    return 10;
  }

  deleteAll() {
    const sub1 = this.fb.habitActions$.subscribe(async (habitActions) => {
      const batch = writeBatch(this.afs.firestore);
      habitActions.forEach((i) => {
        batch.delete(doc(this.afs.firestore, 'habitActions', i.id));
      });
      await batch.commit().catch((e) => console.log(e));
      sub1.unsubscribe();
    });

    const sub2 = this.fb.habitSetups$.subscribe(async (habitSetups) => {
      const batch = writeBatch(this.afs.firestore);
      habitSetups.forEach((i) => {
        batch.delete(doc(this.afs.firestore, 'habitSetups', i.id));
      });
      await batch.commit().catch((e) => console.log(e));
      sub2.unsubscribe();
    });
  }
}
