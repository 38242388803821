import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FirebaseService } from '../../shared/services/firebase.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import { JsHabitSetup } from '../../shared/interfaces/HabitSetup';
import { JsHabitAction } from '../../shared/interfaces/HabitAction';
import { AnyEntityName } from '../../shared/interfaces/Entities';
import { HabitGeneratorService } from '../../shared/services/generators/habit-generator.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-habits',
  templateUrl: './habits.component.html',
  styleUrls: ['./habits.component.scss'],
})
export class HabitsComponent implements OnInit {
  public searchInput = {
    title: '',
  };
  constructor(
    public fbService: FirebaseService,
    public dialog: MatDialog,
    private hgs: HabitGeneratorService,
    private _snackBar: MatSnackBar
  ) {
    this.fbService.getHabitSetups();
    this.fbService.getHabitActions();
  }

  ngOnInit(): void {}

  openDeleteAllDialog() {
    var result = confirm(
      'Do you want to DELETE ALL HABITS permananently from the database?'
    );
    if (result == true) {
      this.hgs.deleteAll();
    }
  }

  // type = 'HabitAction', 'HabitGenerate' etc...
  // payload = actual dat. eg: habit
  // action = only in result. 'Update', 'Create', 'Generate'
  openUpdateDialog(habit: JsHabitSetup) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        type: 'HabitSetup' as AnyEntityName,
        payload: habit,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!res?.action) return;
      if (res.action === 'Update') {
        this.fbService.updateHabitSetup(res.payload);
      } else if (res.action === 'Create') {
        this.fbService.createHabitSetup(res.payload);
      }
    });
  }

  openGenerateDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        type: 'HabitGenerate' as AnyEntityName,
        payload: {
          cType: 'boolean',
          dateFrom: new Date(),
          dateTo: null,
          sun: true,
          mon: true,
          tue: true,
          wed: true,
          thu: true,
          fri: true,
          sat: true,
        },
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result?.payload) return;
      console.log(result);
      await this.hgs.generateHabits(result.payload);
      this._snackBar.open(`Habit created!!`, 'Close', {
        duration: 3000,
      });
    });
  }
}
