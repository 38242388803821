import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HabitsComponent } from './features/habits/habits.component';
import { TodosComponent } from './features/todos/todos.component';
import { JournalsComponent } from './features/journals/journals.component';
import { NotesComponent } from './features/notes/notes.component';
import { ListsComponent } from './features/lists/lists.component';
import { UserComponent } from './features/user/user.component';
import { DataBackupComponent } from './features/data-backup/data-backup.component';

const routes: Routes = [
  { path: 'habits', component: HabitsComponent },
  { path: 'journals', component: JournalsComponent },
  { path: 'notes', component: NotesComponent },
  { path: 'lists', component: ListsComponent },
  { path: 'user', component: UserComponent },
  { path: 'dataBackup', component: DataBackupComponent },
  { path: '**', component: TodosComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
