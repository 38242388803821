<div class="p-2">
    <div class="d-flex float-end">
        <button class="btn btn-primary " (click)="showCreateBackupDialog()">Create New Backup</button>
    </div>
    <h1>Backup List</h1>
    <div class="list-group" *ngFor="let item of dataSnapshots">
        <div class="list-group-item m-1 d-flex align-items-center">
            <span class="badge bg-secondary p-2">{{ item.snapshotId }}</span>
            <div class="flex-column flex-grow-1">
                <div class="d-flex">
                    <h2 class="text-capitalize p-2 m-0">{{ item.title }}</h2>
                    <h3 class="p-2 m-0 align-items-right">{{ item.timestamp.toDate() | date: 'd/M/yy'}}</h3>
                </div>
                <div class="d-flex flex-row">
                    <h3><span class="env badge text-bg-primary ng-star-inserted mx-2">Todos: {{ item.todoCount }}</span></h3>
                    <h3><span class="env badge text-bg-primary ng-star-inserted mx-2">Habits: {{ item.habitCount }}</span></h3>
                    <h3><span class="env badge text-bg-primary ng-star-inserted mx-2">Journals: {{ item.journalCount }}</span></h3>
                    <h3><span class="env badge text-bg-primary ng-star-inserted mx-2">Notes: {{ item.noteCount }}</span></h3>
                    <h3><span class="env badge text-bg-primary ng-star-inserted mx-2">Lists: {{ item.listCount }}</span></h3>
                    <h3><span class="env badge text-bg-primary ng-star-inserted mx-2">Images: {{ item.imageCount }}</span></h3>
                </div>
            </div>
            <button class="btn btn-primary" (click)="showRestoreDataConfirmationDialog(item.snapshotId)">Restore</button>
            <button class="btn btn-secondary m-1 p-1" (click)="showDeleteConfirmationDialog(item.snapshotId)"><span class="bi-trash"></span></button>
        </div>
    </div>
</div>