export const ListGenerate = {
  listQty: {
    type: 'input',
    props: {
      required: true,
      label: 'Quantity',
    },
  },
  listItemsMin: {
    type: 'input',
    props: {
      required: true,
      label: 'Quantity',
    },
  },
  listItemsMax: {
    type: 'input',
    props: {
      required: true,
      label: 'Quantity',
    },
  },
};
