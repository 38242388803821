export const TodoGenerate = {
  from: {
    type: 'dateTime',
    props: {
      required: true,
      label: 'From',
    },
  },
  to: {
    type: 'dateTime',
    props: {
      required: true,
      label: 'To',
    },
  },
  qty: {
    type: 'input',
    props: {
      required: true,
      label: 'Quantity',
    },
  },
  isDone: {
    type: 'input',
    props: {
      required: true,
      label: 'Quantity',
    },
  },
};
