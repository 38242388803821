export const JournalSetup = {
  createdAt: {
    type: 'dateTime',
    props: {
      required: true,
    },
  },
  deletedAt: {
    type: 'dateTime',
  },
  endAt: {
    type: 'dateTime',
  },
  id: {
    props: {
      required: true,
    }, // no type property to hide from the form
  },
  reminderAt: {
    type: 'dateTime',
  },
  startAt: {
    type: 'dateTime',
  },
  isStartTimeSet: {
    type: 'toggle',
  },
  title: {
    type: 'input',
    props: {
      required: true,
    },
  },
  uid: {
    props: {
      required: true,
    },
  },
  localUpdatedAt: {
    type: 'dateTime',
    props: {
      required: true,
    },
  },
  tags: {
    type: 'textarea',
  },
};
