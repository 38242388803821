import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FirebaseService } from '../../shared/services/firebase.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import { JsNote } from '../../shared/interfaces/Note';
import { AnyEntityName } from '../../shared/interfaces/Entities';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NoteGeneratorService } from '../../shared/services/generators/note-generator.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  public searchInput = {
    title: '',
  };
  constructor(
    public fbService: FirebaseService,
    public dialog: MatDialog,
    private ngs: NoteGeneratorService,
    private _snackBar: MatSnackBar
  ) {
    this.fbService.getNotes();
  }

  ngOnInit(): void {}

  openDeleteAllDialog() {
    var result = confirm(
      'Do you want to DELETE ALL NOTES permananently from the database?'
    );
    if (result == true) {
      this.ngs.deleteAll();
    }
  }

  // type = 'NoteAction', 'NoteGenerate' etc...
  // payload = actual dat. eg: note
  // action = only in result. 'Update', 'Create', 'Generate'
  openUpdateDialog(note: JsNote) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        type: 'NoteAction' as AnyEntityName,
        payload: note,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!res?.action) return;
      if (res.action === 'Update') {
        this.fbService.updateNote(res.payload);
      } else if (res.action === 'Create') {
        this.fbService.createNote(res.payload);
      }
    });
  }

  openGenerateDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        type: 'NoteGenerate' as AnyEntityName,
        payload: {
          from: new Date(),
          to: new Date(),
          qty: 3,
        },
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result?.payload) return;
      const total = await this.ngs.generateNotes(result.payload);
      this._snackBar.open(`${total} notes created!!`, 'Close', {
        duration: 3000,
      });
    });
  }
}
