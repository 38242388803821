import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { doc, writeBatch } from 'firebase/firestore';
import { GenerateTodos, JsTodo } from 'src/app/shared/interfaces/Todo';
import {
  getFakeDatesArray,
  getFakeSentence,
  getNewId,
} from 'src/app/shared/utils';
import { currentDocVersion } from '../../interfaces/BaseModel';
import { FirebaseService } from '../firebase.service';

@Injectable({
  providedIn: 'root',
})
export class TodoGeneratorService {
  constructor(private fb: FirebaseService, private afs: AngularFirestore) {}

  async generateTodos(params: GenerateTodos) {
    // Get a new write batch
    if (!this.fb.getUser()?.user?.uid) return;
    const dateArray = getFakeDatesArray(params.from, params.to, params.qty);
    var dataArray: JsTodo[] = [];
    dateArray.forEach((item) => {
      const todo: JsTodo = {
        docCollection: 'todos',
        docVer: currentDocVersion,
        createdAt: new Date(),
        startAt: new Date(item),
        endAt: null,
        images: [],
        isStartTimeSet: false,
        deletedAt: null,
        description: getFakeSentence(2, 20),
        id: getNewId(),
        isComplete: false,
        reminderAt: [],
        tags: [],
        repeat: [],
        title: getFakeSentence(2, 9),
        uid: this.fb.getUser().user.uid,
        localUpdatedAt: new Date(),
        cloudUpdatedAt: null,
        actions: [],
      };
      dataArray.push(todo);
    });
    this.fb.sendDataToServer(dataArray);
    return dateArray.length;
  }

  deleteAll() {
    const sub = this.fb.todos$.subscribe(async (todos) => {
      const batch = writeBatch(this.afs.firestore);
      todos.forEach((i) => {
        batch.delete(doc(this.afs.firestore, 'todos', i.id));
      });
      await batch.commit().catch((e) => console.log(e));
      sub.unsubscribe();
    });
  }
}
