import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { FirebaseService } from './shared/services/firebase.service';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  env = environment;
  constructor(
    public auth: AngularFireAuth,
    private afs: AngularFirestore,
    private fbService: FirebaseService
  ) {}
  title = 'mevolve-admin';
  showSidenav = true;
  // private todosCollection: AngularFirestoreCollection<Item>;
  login() {
    this.auth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((res) => {
        this.fbService.setUser(res);
      });
  }
  logout() {
    this.auth.signOut();
    this.fbService.signoutUser();
  }

  toggleSidenav() {
    this.showSidenav = !this.showSidenav;
  }
}
