export const JournalAction = {
  startAt: {
    type: 'dateTime',
    props: {
      required: true,
    },
  },
  emotion: {
    type: 'input',
  },
  images: {
    type: 'textarea',
  },
  imagesDeletedAt: {
    type: 'textarea',
    props: {
      readonly: true,
      // No editing
    },
  },
  id: {
    props: {
      required: true,
    }, // no type property to hide from the form
  },
  setupId: {
    props: {
      required: true,
    }, // no type property to hide from the form
  },
  description: {
    type: 'input',
  },
  tags: {
    type: 'textarea',
  },
  uid: {
    props: {
      required: true,
    },
  },
  localUpdatedAt: {
    type: 'dateTime',
    props: {
      required: true,
    },
  },
};
