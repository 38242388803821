export const HabitAction = {
  singleAnswer: {
    type: 'input',
  },
  multipleAnswer: {
    type: 'input',
  },
  numericAnswer: {
    type: 'input',
  },
  booleanAnswer: {
    type: 'toggle',
  },
  startAt: {
    type: 'dateTime',
    props: {
      required: true,
    },
  },
  images: {
    type: 'textarea',
  },
  imagesDeletedAt: {
    type: 'textarea',
  },
  id: {
    props: {
      required: true,
    }, // no type property to hide from the form
  },
  setupId: {
    props: {
      required: true,
    }, // no type property to hide from the form
  },
  description: {
    type: 'input',
  },
  tags: {
    type: 'textarea',
  },
  uid: {
    props: {
      required: true,
    },
  },
  localUpdatedAt: {
    type: 'dateTime',
    props: {
      required: true,
    },
  },
};
