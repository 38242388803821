import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { doc, writeBatch } from 'firebase/firestore';
import { GenerateJournalSetups, JsJournalSetup } from 'src/app/shared/interfaces/JournalSetup';
import {
  getFakeSentence,
  getNewId
} from 'src/app/shared/utils';
import { FirebaseService } from '../firebase.service';

@Injectable({
  providedIn: 'root',
})
export class JournalGeneratorService {
  batch: any;
  constructor(private fb: FirebaseService, private afs: AngularFirestore) {}

  async generateJournals(params: GenerateJournalSetups) {
    const journalSetup: JsJournalSetup = {
      docCollection: 'journalSetups',
      docVer: 12,
      createdAt: new Date(),
      deletedAt: null,
      endAt: params.dateTo,
      id: getNewId(),
      reminderAt: [],
      startAt: params.dateFrom,
      isStartTimeSet: false,
      title: getFakeSentence(2, 4),
      uid: this.fb.getUser().user.uid,
      localUpdatedAt: new Date(),
      cloudUpdatedAt: null,
      tags:[],
      repeat: [],
    };
    this.fb.sendDataToServer([journalSetup]);
    return 10;
  }

  deleteAll() {
    const sub1 = this.fb.journalActions$.subscribe(async (journalActions) => {
      const batch = writeBatch(this.afs.firestore);
      journalActions.forEach((i) => {
        batch.delete(doc(this.afs.firestore, 'journalActions', i.id));
      });
      await batch.commit().catch((e) => console.log(e));
      sub1.unsubscribe();
    });

    const sub2 = this.fb.journalSetups$.subscribe(async (journalSetups) => {
      const batch = writeBatch(this.afs.firestore);
      journalSetups.forEach((i) => {
        batch.delete(doc(this.afs.firestore, 'journalSetups', i.id));
      });
      await batch.commit().catch((e) => console.log(e));
      sub2.unsubscribe();
    });
  }
}
