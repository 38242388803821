import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { distinctUntilChanged, tap } from 'rxjs';

@Component({
  selector: 'app-me-date-time-picker',
  templateUrl: './me-date-time-picker.component.html',
  styleUrls: ['./me-date-time-picker.component.scss'],
})
export class MeDateTimePickerComponent extends FieldType implements OnInit {
  showSpinners = true;
  showSeconds = false;
  stepHour = 1;
  stepMinute = 5;
  stepSecond = 60;
  touchUi = false;
  color = 'blue';
  enableMeridian = false;
  disableMinute = false;
  hideTime = false;
  minDate: any;
  maxDate: any;
  disabled = false;
  dateControl!: FormControl;
  formGroup!: FormGroup;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      dateControl: new FormControl(
        this.model[this.props.label!],
        this.props.required ? Validators.required : null
      ),
    });

    this.formGroup.get('dateControl')?.valueChanges.subscribe((val) => {
      this.formControl.setValue(new Date(val));
    });

    this.formGroup.get('dateControl')?.statusChanges.subscribe((val) => {
      if (val !== 'INVALID') {
        this.form.get(this.key as string)?.setErrors(null);
      } else {
        this.form.get(this.key as string)?.setErrors({ required: true });
      }
    });

    this.form
      .get(this.key as string)
      ?.valueChanges.pipe(
        distinctUntilChanged(
          (prev, curr) => prev.toString() === curr.toString()
        ),
        tap((val: Date) => {
          this.formGroup.get('dateControl')?.setValue(new Date(val as Date));
        })
      )
      .subscribe();
  }
}
