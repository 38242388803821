import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FirebaseService } from '../../shared/services/firebase.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import { JsList } from '../../shared/interfaces/List';
import { AnyEntityName } from '../../shared/interfaces/Entities';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ListGeneratorService } from '../../shared/services/generators/list-generator.service';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss'],
})
export class ListsComponent implements OnInit {
  public searchInput = {
    title: '',
  };
  public selectedList: JsList | undefined;
  constructor(
    public fbService: FirebaseService,
    public dialog: MatDialog,
    private lgs: ListGeneratorService,
    private _snackBar: MatSnackBar
  ) {
    this.fbService.getLists();
  }

  ngOnInit(): void {}

  openDeleteAllDialog() {
    var result = confirm(
      'Do you want to DELETE ALL LISTS permananently from the database?'
    );
    if (result == true) {
      this.lgs.deleteAll();
    }
  }

  // type = 'ListAction', 'ListGenerate' etc...
  // payload = actual dat. eg: list
  // action = only in result. 'Update', 'Create', 'Generate'
  openUpdateDialog(list: JsList) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        type: 'ListAction' as AnyEntityName,
        payload: list,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!res?.action) return;
      if (res.action === 'Update') {
        this.fbService.updateList(res.payload);
      } else if (res.action === 'Create') {
        this.fbService.createList(res.payload);
      }
    });
  }

  openGenerateDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        type: 'ListGenerate' as AnyEntityName,
        payload: {
          listQty: 3,
          listItemsMin: 0,
          listItemsMax: 15,
        },
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result?.payload) return;
      const total = await this.lgs.generateLists(result.payload);
      this._snackBar.open(`${total} lists created!!`, 'Close', {
        duration: 3000,
      });
    });
  }
}
