<div class="d-flex flex-column">
  <div class="h5 m-0 p-3 search-bar">
    <input type="text" [(ngModel)]="searchInput.title" placeholder="Title search">
    <button class="btn btn-primary float-end" (click)="openGenerateDialog()">Generate Notes</button>
    <button class="btn btn-danger float-end me-3" (click)="openDeleteAllDialog()">Delete All</button>
  </div>
  <div class="col-wrapper d-flex flex-row flex-grow-1">
    <div class="flex-grow-1">
      <div class="fs-6 lh-lg ps-2">Past ({{(fbService.notes$ | async | meDateFilter:'past':'createdAt' | filterBy:
        searchInput)?.length}})</div>
      <div class="scroll-container">

        <!-- Past -->
        <div *ngFor="let item of fbService.notes$ | async | meDateFilter:'past':'createdAt' | filterBy:
        searchInput | orderBy: '-createdAt'" (click)="openUpdateDialog(item)">
          <div *ngIf="item.createdAt&& (item.createdAt | dfnsIsPast)" class="card">
            <div class="fs-6 lh-base text-break">{{item.title}}</div>
            <div class="text-muted text-break">{{item.journalEntry}}</div>
            <div class="text-muted text-break">{{item.dateTime | date: 'd/M/yy'}} {{item.time ?
              (item.time | date:
              'shortTime') : ''}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow-1">
      <div class="fs-6 lh-lg ps-2">Today ({{(fbService.notes$ | async | meDateFilter:'today':'createdAt' | filterBy:
        searchInput)?.length}})

      </div>
      <div class="scroll-container">
        <!-- Today -->
        <div *ngFor="let item of fbService.notes$ | async| meDateFilter:'today':'createdAt'| filterBy:
        searchInput | orderBy: '-createdAt'" class="card" (click)="openUpdateDialog(item)">
          <div>
            <div class="fs-6 lh-base text-break">{{item.title}}</div>
            <div class="text-muted text-break">{{item.journalEntry}}</div>
            <div class="text-muted text-break">{{item.dateTime | date: 'd/M/yy'}} {{item.time ?
              (item.time | date:
              'shortTime') : ''}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
